<template>

  <Navigation />

  <main class="constrain">
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </main>

</template>

<script>

import Navigation from '@/components/structure/Navigation.vue';

export default {
  components: { Navigation },
};
</script>
